export class SwaggerController {
  constructor(session) {
    this.session = session;
    this.versions = [
      {
        value: "V03",
        title: "Salaxy v03",
        text: "Latest version 03 of the main Salaxy API.",
        ui: {
          url: "/docs/swagger/salaxy-03.json"
        }
      },
      {
        value: "V02",
        title: "Salaxy v02",
        text: "Previous version of the main Salaxy API. Combined version of the API: 02 and 03RC.",
        ui: {
          url: "/docs/swagger/salaxy-02.json"
        }
      },
      {
        value: "V04",
        title: "Salaxy v04",
        text: "Next V04 version of the API. To be released 04-05/2024.",
        ui: {
          url: "/docs/swagger/salaxy-04.json"
        }
      },
      {
        value: "Docs",
        title: "Documentation generation",
        text: "Documentation version is a separate API that provides documentation over all (other) API versions. Also enums and schemas.",
        ui: {
          anon: true,
          url: "/docs/swagger/salaxy-docs.json"
        }
      },
      {
        value: "OAuth2",
        title: "OAuth2 API for authentication and security",
        text: "OAuth2 provides the methods for authentication, impersonation, federation, authentication and other security features.",
        ui: {
          anon: true,
          url: "/docs/swagger/salaxy-oauth2.json"
        }
      },
      {
        value: "IrEpr",
        title: "BETA: Incomes Register, Earnings Payment Report",
        text: "BETA version, ask before using: API for connecting to Incomes register to view and modify Earnings Payment Report",
        ui: {
          url: "/docs/swagger/salaxy-ir-epr.json"
        }
      },
      {
        value: "IrPsr",
        title: "BETA: Incomes Register, Payer Summary Report",
        text: "BETA version, ask before using: API for connecting to Incomes register to view and modify Payer Summary Report",
        ui: {
          url: "/docs/swagger/salaxy-ir-psr.json"
        }
      }
    ];
  }
  $onInit() {
    this.setVersion("V03");
  }
  get iFrameUrl() {
    if (!this.current?.ui?.url) {
      return void 0;
    }
    return `/swagger/index.html?url=${this.current.ui.url}&access_token=${this.current.ui.access_token}`;
  }
  setVersion(version) {
    const current = this.versions.find((v) => v.value === version);
    if (!current) {
      return;
    }
    current.ui = current.ui || {};
    if (this.session.isAuthenticated && !current.ui.anon) {
      current.ui.access_token = this.session.getCurrentToken();
    }
    this.current = current;
    this.currentId = current.value;
  }
}
SwaggerController.$inject = ["SessionService"];
