export class DocsController {
  constructor(docsService, $scope, $location, $routeParams) {
    this.docsService = docsService;
    this.$scope = $scope;
    this.$location = $location;
    this.$routeParams = $routeParams;
    this.filter = {};
    this.$onInit = () => {
      if (this.docsService.isReady) {
        this.init();
      }
      this.docsService.subscribe(this.$scope, () => {
        this.init();
      });
    };
  }
  get currentTab() {
    return this.docsService.currentTab;
  }
  set currentTab(value) {
    this.docsService.currentTab = value;
  }
  get classes() {
    return this.docsService.classes;
  }
  get enums() {
    return this.docsService.enums;
  }
  get groups() {
    return this.docsService.groups.all;
  }
  goto(item) {
    let viewName = "classes";
    switch (item.kind) {
      case "enum":
        viewName = "enums";
        break;
      case "class":
      case "interface":
        viewName = "classes";
        break;
      default:
        throw new Error("Item kind not supported: " + item.kind);
    }
    this.$location.url(`/docs/${viewName}/${item.group}/${item.name}`);
  }
  getGitUrl(source) {
    if (!source) {
      return null;
    }
    return "https://gitlab.com/salaxy/salaxy-lib-ng1/-/tree/master/src/ts/" + source.replace("salaxy-lib-ng1/src/ts/", "");
  }
  getRouteData() {
    if (!this.$routeParams) {
      return {};
    }
    const fullId = (this.$routeParams.crudItemId || "").split("/");
    return {
      name: fullId[1] || null,
      viewName: this.$routeParams.viewName || null,
      group: fullId[0] || null
    };
  }
  exampleLoaded(angularEvent, src) {
    console.debug("Example loaded:", angularEvent);
  }
  init() {
    const routeData = this.getRouteData();
    this.currentType = this.currentType || (routeData.viewName === "example" ? "classes" : routeData.viewName);
    this.currentName = this.currentName || routeData.name;
    if (this.currentName) {
      switch (this.currentType) {
        case "classes":
          this.current = this.classes.find((x) => x.name === this.currentName);
          this.filter.group = this.current?.group;
          this.filter.kind = this.current?.kind;
          break;
        case "enums":
          this.current = this.enums.find((x) => x.name === this.currentName);
          break;
        default:
          throw new Error(`Viewname ${this.currentType} currently not supported.`);
      }
      if (!this.current) {
        throw new Error(`Object with name ${this.currentName} not found.`);
      }
    } else {
      this.current = null;
      switch (this.currentType) {
        case "classes":
          if (routeData.group) {
            this.filter.group = routeData.group;
          }
          break;
        case "enums":
          break;
        default:
          throw new Error(`Viewname ${this.currentType} currently not supported.`);
      }
    }
  }
}
DocsController.$inject = ["DocsService", "$scope", "$location", "$routeParams"];
