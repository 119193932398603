import {DemoDataController} from "../controllers/index.js";
export class AssureBo {
  constructor() {
    this.bindings = {
      mode: "@"
    };
    this.transclude = {
      main: "main"
    };
    this.controller = DemoDataController;
    this.templateUrl = "/ng1/templates/AssureBo.html";
  }
}
