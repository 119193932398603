import {TypedocLogic} from "../../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
import {marked} from "../../../_snowpack/pkg/marked.js";
import hljs from "../../../_snowpack/pkg/highlightjs/lib/core.js";
import javascript from "../../../_snowpack/pkg/highlightjs/lib/languages/javascript.js";
import csharp from "../../../_snowpack/pkg/highlightjs/lib/languages/csharp.js";
import css from "../../../_snowpack/pkg/highlightjs/lib/languages/css.js";
import json from "../../../_snowpack/pkg/highlightjs/lib/languages/json.js";
import less from "../../../_snowpack/pkg/highlightjs/lib/languages/less.js";
import xml from "../../../_snowpack/pkg/highlightjs/lib/languages/xml.js";
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("csharp", csharp);
hljs.registerLanguage("css", css);
hljs.registerLanguage("json", json);
hljs.registerLanguage("less", less);
hljs.registerLanguage("xml", xml);
marked.setOptions({
  highlight: (code, lang, callback) => {
    if (!lang) {
      const result = hljs.highlightAuto(code, ["javascript", "csharp", "html", "css", "json", "less", "xml"]);
      return result.value;
    } else {
      return hljs.highlight(lang, code).value;
    }
  }
});
window.sxyHighlight = (query) => {
  document.querySelectorAll(query).forEach((elem) => {
    hljs.highlightElement(elem);
  });
};
export class DocsFilters {
}
DocsFilters.sxydevMarkdown = ["$sce", ($sce) => {
  return (markdown) => {
    if (markdown == null) {
      return null;
    }
    return $sce.getTrustedHtml(marked(markdown).replace("<pre", `<pre class="hljs"`));
  };
}];
DocsFilters.sxydevCode = ["$sce", ($sce) => {
  return (code, lang) => {
    if (code == null) {
      return null;
    }
    let html;
    if (!lang) {
      html = hljs.highlightAuto(code).value;
    } else {
      html = hljs.highlight(lang, code).value;
    }
    return $sce.getTrustedHtml(html);
  };
}];
DocsFilters.sxydevType = ["$sce", "DocsService", ($sce, docsService) => {
  return (type) => {
    return $sce.getTrustedHtml(TypedocLogic.getTypeHtml(type, docsService.typeLinker));
  };
}];
