import angular from "../_snowpack/pkg/angular.js";
import {Translations} from "../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
import {SalaxyNg1ComponentsModule} from "../_snowpack/link/dist/@salaxy/ng1/salaxy-lib-ng1.js";
import {sitemap} from "./sitemap.js";
import {AssertionDemoController, DocsController, SecurityDemoController, SwaggerController} from "./controllers/index.js";
import {DocsService} from "./other/index.js";
import {DocsFilters} from "../ng1/index.js";
import colorPicker from "../_snowpack/pkg/angularjs-color-picker.js";
export const module = angular.module("Salaxy.developersWeb", ["ngRoute", "salaxy.ng1.components.all", "color.picker"]).config([
  "RouteHelperProvider",
  function(routeHelperProvider) {
    console.debug(`App module registering: ${SalaxyNg1ComponentsModule?.name} + ${colorPicker?.name}`);
    routeHelperProvider.homeDefault("home").customSection("home").customSection("api").customSection("js").customSection("docs").customSection("security").customSection("ui").customSection("other").commit();
  }
]).constant("SITEMAP", sitemap).run([
  "SessionService",
  function(sessionService) {
    sessionService.signInErrorUrl = "/home/error-oauth";
    Translations.setLocalesPath("/locales");
  }
]).run(["OnboardingService", function(onboardingService) {
}]).service("DocsService", DocsService).controller("AssertionDemoController", AssertionDemoController).controller("DocsController", DocsController).controller("SecurityDemoController", SecurityDemoController).controller("SwaggerController", SwaggerController).filter("sxydevCode", DocsFilters.sxydevCode).filter("sxydevMarkdown", DocsFilters.sxydevMarkdown).filter("sxydevType", DocsFilters.sxydevType);
