import {Arrays, SitemapVisibility} from "../../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
import {SitemapHelper} from "../../../_snowpack/link/dist/@salaxy/ng1/salaxy-lib-ng1.js";
export class DocsComponentsController {
  constructor(docsService, $scope, $location, $routeParams, uiHelpers, naviService) {
    this.docsService = docsService;
    this.$scope = $scope;
    this.$location = $location;
    this.$routeParams = $routeParams;
    this.uiHelpers = uiHelpers;
    this.naviService = naviService;
    this.filter = {};
    this.$onInit = () => {
      if (this.docsService.isReady) {
        this.init();
      }
      this.docsService.subscribe(this.$scope, () => {
        this.init();
      });
      this.setCurrentTranslationPrefix();
    };
  }
  get currentTab() {
    return this.docsService.currentTab;
  }
  set currentTab(value) {
    this.docsService.currentTab = value;
  }
  get classes() {
    return this.docsService.classes;
  }
  get enums() {
    return this.docsService.enums;
  }
  get components() {
    return this.docsService.components;
  }
  get controllers() {
    return this.docsService.controllers;
  }
  get filters() {
    return this.docsService.filters;
  }
  get directives() {
    return this.docsService.directives;
  }
  get groups() {
    if (this.filter.lib) {
      return this.docsService.groups[this.filter.lib];
    }
    return this.docsService.groups.all;
  }
  get componentGroups() {
    const isComponentsLoaded = !!this.components;
    return this.uiHelpers.cache(this, "componentGroups", () => {
      let totalCount = 0;
      let allGroups = Object.keys(Arrays.groupBy(this.components ?? [], (x) => x.group));
      const result = SitemapHelper.getSection(this.naviService.getSitemap(), "components").children.filter((x) => SitemapHelper.getVisibility(x, SitemapVisibility.Visible)).map((x) => {
        const url = x.url.replace("/*", "");
        const value = url.substr(url.lastIndexOf("/") + 1);
        allGroups = allGroups.filter((x2) => x2 != value);
        const count = this.components?.filter((x2) => x2.group === value).length || 0;
        totalCount += count;
        return {
          text: x.title,
          value,
          ui: {
            url,
            count
          }
        };
      });
      result.push({
        text: "Other",
        value: allGroups.join(", "),
        ui: {
          url: null,
          count: isComponentsLoaded ? this.components.length - totalCount : 0
        }
      });
      return result;
    }, () => isComponentsLoaded);
  }
  goto(item) {
    let viewName = "classes";
    switch (item.kind) {
      case "enum":
        viewName = "enums";
        break;
      case "class":
      case "interface":
        viewName = "classes";
        break;
      case "controller":
      case "controllerBase":
        viewName = "controllers";
        break;
      case "component":
        viewName = "components";
        break;
      default:
        throw new Error("Item kind not supported: " + item.kind);
    }
    this.$location.url(`/docs/${viewName}/${item.group}/${item.name}`);
  }
  getTemplateUrl() {
    return this.currentType === "components" ? this.current.defaultTemplate.replace("salaxy-components/", "https://gitlab.com/salaxy/salaxy-lib-ng1/-/tree/master/src/templates/") : null;
  }
  getGitUrl(source) {
    if (!source) {
      return null;
    }
    return "https://gitlab.com/salaxy/salaxy-lib-ng1/-/tree/master/src/ts/" + source.replace("salaxy-lib-ng1/src/ts/", "");
  }
  getRouteData() {
    if (!this.$routeParams) {
      return {};
    }
    const fullId = (this.$routeParams.crudItemId || "").split("/");
    return {
      name: fullId[1] || null,
      viewName: this.$routeParams.viewName || null,
      group: fullId[0] || null
    };
  }
  exampleLoaded(angularEvent, src) {
    console.debug("Example loaded:", angularEvent);
  }
  init() {
    const routeData = this.getRouteData();
    this.currentType = this.currentType || (routeData.viewName === "example" ? "components" : routeData.viewName);
    this.currentName = this.currentName || routeData.name;
    if (this.currentName) {
      switch (this.currentType) {
        case "classes":
        case "controllers":
          this.current = this.classes.find((x) => x.name === this.currentName);
          this.filter.group = this.current?.group;
          this.filter.kind = this.current?.kind;
          if (this.filter.kind === "controllerBase") {
            this.filter.kind = "controller";
          }
          break;
        case "components":
          this.current = this.components.find((x) => x.name === this.currentName);
          this.filter.group = this.current?.group;
          this.filter.kind = this.current?.kind;
          break;
        case "enums":
          this.current = this.enums.find((x) => x.name === this.currentName);
          break;
        default:
          throw new Error(`Viewname ${this.currentType} currently not supported.`);
      }
      if (!this.current) {
        throw new Error(`Object with name ${this.currentName} not found.`);
      }
      this.filter.lib = this.current.lib;
    } else {
      this.current = null;
      switch (this.currentType) {
        case "classes":
          if (routeData.group === "ng1" || routeData.group === "core") {
            this.filter.lib = "@salaxy/" + routeData.group;
          } else if (routeData.group) {
            this.filter.group = routeData.group;
          }
          break;
        case "components":
          this.filter.kind = "component";
          this.filter.lib = "@salaxy/ng1";
          this.filter.group = routeData.group;
          break;
        case "controllers":
          this.filter.kind = "controller";
          this.filter.lib = "@salaxy/ng1";
          this.filter.group = routeData.group;
          break;
        case "enums":
          if (routeData.group === "core") {
            this.filter.lib = "@salaxy/core";
          } else {
            this.filter.lib = "@salaxy/ng1";
          }
          break;
        case "filters":
        case "directives":
          break;
        default:
          throw new Error(`Viewname ${this.currentType} currently not supported.`);
      }
    }
  }
  setCurrentTranslationPrefix() {
    if (this.current && this.current.kind) {
      switch (this.current?.kind) {
        case "component":
          this.translationPrefix = "SALAXY.NG1." + this.current.name + "Component";
          break;
        case "interface":
          this.translationPrefix = "SALAXY.MODEL" + this.current.name;
          break;
      }
    }
  }
}
DocsComponentsController.$inject = ["DocsService", "$scope", "$location", "$routeParams", "UiHelpers", "NaviService"];
