import {TypedocLogic} from "../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
export class DocsService {
  constructor($rootScope, $http) {
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.classes = [];
    this.enums = [];
    this.groups = {
      all: {},
      "@salaxy/core": {
        api: "API wrappers",
        util: "Core utilities",
        logic: "Core business logic",
        model: "Core model"
      }
    };
    this.typeLinker = (type) => {
      if (type.id) {
        const enumById = this.enums.find((x) => x.id === type.id && x.name === type.name);
        if (enumById) {
          return `#/docs/enums/${enumById.group}/${enumById.name}`;
        }
        const classById = this.classes.find((x) => x.id === type.id && x.name === type.name);
        if (classById) {
          return `#/docs/classes/${classById.group}/${classById.name}`;
        }
      }
      const enumType = this.enums.find((x) => x.name === type.name);
      if (enumType) {
        return `#/docs/enums/${enumType.group}/${enumType.name}`;
      }
      const classOrInterface = this.classes.find((x) => x.name === type.name);
      if (classOrInterface) {
        return `#/docs/classes/${classOrInterface.group}/${classOrInterface.name}`;
      }
      return null;
    };
    this.reloadDocs();
  }
  reloadDocs() {
    this.loadStartTime = new Date();
    this.isReady = false;
    this.$http.get("/docs/ts/core.json", {
      responseType: "json"
    }).then((response) => {
      const typeDocJson = response.data;
      if (!typeDocJson || typeDocJson.children.length < 10) {
        throw new Error("Invalid generated typedoc: Core.");
      }
      this.enums = this.enums.concat(TypedocLogic.getEnums(typeDocJson, "@salaxy/core"));
      this.classes = this.classes.concat(TypedocLogic.getClasses(typeDocJson, "@salaxy/core"));
      this.isReady = true;
      this.classes.forEach((x) => {
        if (!this.groups[x.lib]) {
          this.groups[x.lib] = {};
        }
        if (!this.groups[x.lib][x.group]) {
          this.groups[x.lib][x.group] = x.group;
        }
      });
      Object.keys(this.groups).slice(1).forEach((key) => this.groups.all = {...this.groups.all, ...this.groups[key]});
      console.info(`Load and parse ${(new Date().getTime() - this.loadStartTime.getTime()) / 1e3} secs.`);
      this.notify();
    });
  }
  findClass(name) {
    if (!name) {
      return null;
    }
    name = name.trim();
    return this.classes.find((x) => x.name === name);
  }
  findMember(className, memberName) {
    const classDoc = this.findClass(className);
    if (!classDoc) {
      return null;
    }
    memberName = memberName.trim();
    return classDoc.members.find((x) => x.name === memberName);
  }
  subscribe(scope, callback) {
    const handler = this.$rootScope.$on("typedoc-service-event", callback);
    scope.$on("$destroy", handler);
  }
  notify() {
    this.$rootScope.$emit("typedoc-service-event");
  }
}
DocsService.$inject = ["$rootScope", "$http"];
