export const sitemap = [
  {
    title: "About Salaxy",
    url: "#/",
    id: "home",
    children: [
      {
        title: "Contact us",
        url: "#/home/people"
      },
      {
        title: "About our company",
        url: "#/home/spl"
      }
    ]
  },
  {
    title: "Salaxy JSON API",
    url: "#/api/index",
    id: "api",
    children: [
      {
        title: "API reference",
        url: "#/api/swagger",
        isFullWidth: true
      },
      {
        title: "Business objects browser",
        url: "#/api/models",
        isFullWidth: true
      },
      {
        title: "OData lists",
        url: "#/api/odata"
      },
      {
        title: "Row types",
        url: "#/api/row-types",
        isFullWidth: true
      },
      {
        title: "Accounting data",
        url: "#/api/accounting"
      },
      {
        title: "Payslips",
        url: "#/api/payslips"
      }
    ]
  },
  {
    title: "Custom User Interface",
    url: "#/ui/index",
    id: "ui",
    children: [
      {
        title: "Getting started",
        url: "#/ui/index"
      },
      {
        title: "Embedded UI (iframe)",
        url: "#/ui/iframe"
      }
    ]
  },
  {
    title: "Security and Auth",
    url: "#/security/index",
    id: "security",
    children: [
      {
        title: "Getting started",
        url: "#/security/index"
      },
      {
        title: "Logged-in account",
        url: "#/security/current"
      },
      {
        title: "Password Authorization Flow",
        url: "#/security/password"
      },
      {
        title: "Single Sign-on (impersonation flow)",
        url: "#/security/assertion"
      },
      {
        title: "Assure Company Account",
        url: "#/security/assure-company-account"
      },
      {
        title: "Federation for banks etc.",
        url: "#/security/federation"
      }
    ]
  },
  {
    title: "Plain JavaScript stack",
    url: "#/js/index",
    id: "js",
    children: [
      {
        title: "Simple calculator",
        url: "#/js/samples"
      },
      {
        title: "Reference (@salaxy/core)",
        url: "#/docs/classes/api",
        isFullWidth: true,
        children: [
          {
            title: "API classes (stubs)",
            url: "#/docs/classes/api/*",
            isFullWidth: true
          },
          {
            title: "All enums",
            url: "#/docs/enums/*",
            visibility: "hidden",
            isFullWidth: true
          },
          {
            title: "All classes",
            url: "#/docs/classes/*",
            visibility: "hidden",
            isFullWidth: true
          },
          {
            title: "Data model (interfaces)",
            url: "#/docs/classes/model/*",
            isFullWidth: true
          },
          {
            title: "Enumerations",
            url: "#/docs/enums/core",
            isFullWidth: true
          },
          {
            title: "Business logic",
            url: "#/docs/classes/logic/*",
            isFullWidth: true
          },
          {
            title: "Generic Utils",
            url: "#/docs/classes/util/*",
            isFullWidth: true
          },
          {
            title: "Usecases",
            url: "#/docs/classes/usecase/*",
            isFullWidth: true
          },
          {
            title: "Full core library",
            url: "#/docs/classes",
            isFullWidth: true
          }
        ]
      }
    ]
  },
  {
    title: "Web components (AngularJS)",
    url: "#/other/ng1",
    id: "ng1"
  },
  {
    title: "Other technologies",
    url: "#/other/index",
    id: "other",
    children: [
      {
        title: "SXY Components",
        url: "#/other/sxy",
        children: []
      },
      {
        title: ".Net client stack",
        url: "#/other/dotnet",
        children: []
      },
      {
        title: "PHP integration",
        url: "#/other/php",
        id: "php",
        children: []
      }
    ]
  }
];
