import {Configs} from "../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
export class AssertionDemoController {
  constructor(ajax, $scope, $http, $timeout, authorizedAccountService, sessionService, $sce) {
    this.ajax = ajax;
    this.$scope = $scope;
    this.$http = $http;
    this.$timeout = $timeout;
    this.authorizedAccountService = authorizedAccountService;
    this.sessionService = sessionService;
    this.$sce = $sce;
    this.model = {
      accountType: null,
      certRequest: {},
      certResult: null,
      testAssertionJwt: null,
      testAssertionHeaderJson: null,
      testAssertionPayloadJson: null,
      testAssertionSignature: null,
      certificateCreationError: null,
      testRequestJson: null,
      testResponseJson: null,
      sessionRequestHeader: null,
      sessionResponseJson: null,
      certificateFile: null,
      sampleJson: {
        requestStructureJson: {
          grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
          assertion: "<assertion JWT>"
        },
        assertionHeaderStructureJson: {
          typ: "JWT",
          alg: "RS256",
          x5t: "<thumbprint for the X.509 certificate>",
          x5c: "array containing one base64 encoded X.509 certificate string"
        },
        assertionPayloadStructureJson: {
          iss: "<issuing application>",
          sub: "<named account id>",
          aud: "https://test-api.salaxy.com/oauth2/token",
          exp: "<expiration time>"
        }
      }
    };
    this.fedModel = {
      federationData: null,
      certificateBytes: null,
      certificatePwd: null,
      token: null,
      certificateFile: null,
      customFederationDataJson: null
    };
    this.postResult = null;
  }
  $onInit() {
  }
  getWwwUrl() {
    return Configs.current.wwwServer || "https://test-www.palkkaus.fi";
  }
  getApiUrl(path) {
    return this.$sce.trustAsResourceUrl(this.ajax.getServerAddress() + path);
  }
  getCurrentAccount() {
    return this.sessionService.isAuthenticated ? this.sessionService.getSession().currentAccount : null;
  }
  getAuthorizingAccounts() {
    if (this.authorizedAccountService.getAuthorizingAccounts().length && !this.authorizingAccountsCache) {
      this.authorizingAccountsCache = this.authorizedAccountService.getAuthorizingAccounts().reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue.id + " - " + currentValue.avatar.displayName;
        return accumulator;
      }, {});
    }
    return this.authorizingAccountsCache;
  }
  createCertificate() {
    if (this.model.accountType == "current") {
      this.model.certRequest.subject = this.getCurrentAccount().id;
    }
    this.ajax.postJSON("/v02/api/accounts/sso-demo/create-certificate", this.model.certRequest).then((result) => {
      this.model.certResult = result;
      if (result.token) {
        this.model.testRequestJson = {
          grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
          assertion: result.token
        };
      }
    }).catch((error) => {
      this.model.certResult = {
        error: "HTTP-error: " + error.data || error
      };
    });
  }
  getAccessToken() {
    this.ajax.postJSON(this.ajax.getServerAddress() + "/oauth2/token", this.model.testRequestJson).then((result) => {
      this.model.testResponseJson = result;
      if (result.access_token) {
        this.model.sessionRequestHeader = "Authorization: Bearer " + result.access_token;
      }
    }).catch((error) => {
      this.model.testResponseJson = {
        error: "HTTP-error: " + error.data || error
      };
    });
  }
  getSession() {
    const model = this.model;
    this.$http({
      method: "GET",
      url: this.ajax.getServerAddress() + "/v02/api/session/current",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + model.testResponseJson.access_token
      }
    }).then(function successCallback(response) {
      model.sessionResponseJson = response;
    }, function errorCallback(response) {
      model.sessionResponseJson = {error: response};
    });
  }
  uploadCertificateFile(file, isFederation) {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.$timeout(() => {
        const results = reader.result.split(",");
        if (results.length > 0) {
          if (isFederation) {
            this.fedModel.certificateBytes = results[results.length - 1];
          } else {
            this.model.certRequest.certificateBytes = results[results.length - 1];
          }
        }
      });
    };
  }
  getFederationData(clearCurrent) {
    if (clearCurrent && this.fedModel) {
      this.fedModel.federationData = null;
    }
    this.ajax.postJSON("/v02/api/test/federation", this.fedModel).then((result) => {
      this.fedModel = result;
    }).catch((error) => {
      alert("HTTP-error: " + error.data || error);
    });
  }
  copyCurrentUser() {
    this.fedModel.customFederationDataJson = JSON.stringify(this.fedModel.federationData, null, " ");
  }
}
AssertionDemoController.$inject = ["AjaxNg1", "$scope", "$http", "$timeout", "AuthorizedAccountService", "SessionService", "$sce"];
