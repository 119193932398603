import angular from "../../../_snowpack/pkg/angular.js";
import {TypedocLogic} from "../../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
import {ComponentsRegistration, ControllersRegistration} from "../../../_snowpack/link/dist/@salaxy/ng1/salaxy-lib-ng1.js";
export class DocsService {
  constructor($rootScope, $http, $q) {
    this.$rootScope = $rootScope;
    this.$http = $http;
    this.$q = $q;
    this.classes = [];
    this.enums = [];
    this.groups = {
      all: {},
      "@salaxy/core": {
        api: "API wrappers",
        util: "Core utilities",
        logic: "Core business logic",
        model: "Core model"
      },
      "@salaxy/ng1": {
        account: "Account edit and view",
        calc: "Salary Calculation",
        communications: "Communications",
        "form-controls": "Form controls",
        helpers: "Misc. helpers and tools",
        odata: "OData lists and querying",
        invoices: "Payments and invoices",
        report: "Reports and accounting",
        worker: "Worker and employment",
        obsolete: "Obsolete and phasing out",
        personal: "Personal: Worker/Household webs",
        bases: "Base classes",
        undercon: "Functionality under construction"
      }
    };
    this.typeLinker = (type) => {
      if (type.id) {
        const enumById = this.enums.find((x) => x.id === type.id && x.name === type.name);
        if (enumById) {
          return `#/docs/enums/${enumById.group}/${enumById.name}`;
        }
        const classById = this.classes.find((x) => x.id === type.id && x.name === type.name);
        if (classById) {
          if (classById.kind === "controller" || classById.kind === "controllerBase") {
            return `#/docs/controllers/${classById.group}/${classById.name}`;
          }
          return `#/docs/classes/${classById.group}/${classById.name}`;
        }
      }
      const enumType = this.enums.find((x) => x.name === type.name);
      if (enumType) {
        return `#/docs/enums/${enumType.group}/${enumType.name}`;
      }
      const classOrInterface = this.classes.find((x) => x.name === type.name);
      if (classOrInterface) {
        if (classOrInterface.kind === "controller" || classOrInterface.kind === "controllerBase") {
          return `#/docs/controllers/${classOrInterface.group}/${classOrInterface.name}`;
        }
        return `#/docs/classes/${classOrInterface.group}/${classOrInterface.name}`;
      }
      return null;
    };
    this.reloadDocs();
  }
  reloadDocs() {
    this.loadStartTime = new Date();
    this.isReady = false;
    const coreLoading = this.$http.get("/docs/ts/core.json", {
      responseType: "json"
    }).then((response) => {
      const typeDocJson = response.data;
      if (!typeDocJson || typeDocJson.children.length < 10) {
        throw new Error("Invalid generated typedoc: Core.");
      }
      this.enums = this.enums.concat(TypedocLogic.getEnums(typeDocJson, "@salaxy/core"));
      this.classes = this.classes.concat(TypedocLogic.getClasses(typeDocJson, "@salaxy/core"));
    });
    const ng1Loading = this.$http.get("/docs/ts/ng1.json", {
      responseType: "json"
    }).then((response) => {
      const typeDocJson = response.data;
      if (!typeDocJson || typeDocJson.children.length < 10) {
        throw new Error("Invalid generated typedoc: NG1.");
      }
      this.enums = this.enums.concat(TypedocLogic.getEnums(typeDocJson, "@salaxy/ng1"));
      const ng1Classes = TypedocLogic.getClasses(typeDocJson, "@salaxy/ng1");
      this.classes = this.classes.concat(ng1Classes);
      this.controllers = this.getControllers(ng1Classes);
      this.components = this.getComponents(typeDocJson, ng1Classes);
      this.filters = this.getFilters(ng1Classes);
      this.directives = this.getDirectives(ng1Classes);
    });
    this.$q.all([ng1Loading, coreLoading]).then(() => {
      this.isReady = true;
      this.classes.forEach((x) => {
        if (!this.groups[x.lib]) {
          this.groups[x.lib] = {};
        }
        if (!this.groups[x.lib][x.group]) {
          this.groups[x.lib][x.group] = x.group;
        }
      });
      Object.keys(this.groups).slice(1).forEach((key) => this.groups.all = {...this.groups.all, ...this.groups[key]});
      console.info(`Load and parse ${(new Date().getTime() - this.loadStartTime.getTime()) / 1e3} secs.`);
      this.notify();
    }).catch((reason) => {
      console.error(reason);
    });
  }
  findClass(name) {
    if (!name) {
      return null;
    }
    name = name.trim();
    return this.classes.find((x) => x.name === name);
  }
  findMember(className, memberName) {
    const classDoc = this.findClass(className);
    if (!classDoc) {
      return null;
    }
    memberName = memberName.trim();
    return classDoc.members.find((x) => x.name === memberName);
  }
  subscribe(scope, callback) {
    const handler = this.$rootScope.$on("typedoc-service-event", callback);
    scope.$on("$destroy", handler);
  }
  notify() {
    this.$rootScope.$emit("typedoc-service-event");
  }
  getDirectives(allClasses) {
    const registeredFiltersType = allClasses.find((x) => x.name === "DirectivesRegistration").members.find((x) => x.name === "getDirectives").type;
    const directives = registeredFiltersType.declaration.children.map((filterRegTypedoc) => {
      const defaultValueArr = filterRegTypedoc.defaultValue.split(".");
      const classDoc = this.findClass(defaultValueArr[0]);
      if (!classDoc) {
        return null;
      }
      if (classDoc.implements.find((x) => x.name === "IDirective")) {
        const result2 = angular.copy(classDoc);
        result2.name = filterRegTypedoc.name;
        result2.directiveName = this.snakeCase(result2.name);
        return result2;
      }
      const member = this.findMember(defaultValueArr[0], defaultValueArr[1].replace("()", ""));
      if (!member) {
        return null;
      }
      const result = angular.copy(member);
      result.name = filterRegTypedoc.name;
      result.directiveName = this.snakeCase(result.name);
      return result;
    });
    return directives.filter((x) => x != null);
  }
  getFilters(allClasses) {
    const registeredFiltersType = allClasses.find((x) => x.name === "FiltersRegistration").members.find((x) => x.name === "getFilters").type;
    const filters = registeredFiltersType.declaration.children.map((filterRegTypedoc) => {
      const defaultValueArr = filterRegTypedoc.defaultValue.split(".");
      const member = this.findMember(defaultValueArr[0], defaultValueArr[1]);
      if (!member) {
        return null;
      }
      const result = angular.copy(member);
      result.name = filterRegTypedoc.name;
      return result;
    });
    return filters.filter((x) => x != null);
  }
  getControllers(allClasses) {
    const registeredControllers = ControllersRegistration.getControllers();
    const controllers = allClasses.filter((x) => x.kind === "controllerBase");
    controllers.forEach((ctrl) => {
      if (registeredControllers[ctrl.name]) {
        ctrl.kind = "controller";
      }
    });
    return controllers;
  }
  getComponents(typedoc, classes) {
    const registeredComponents = ComponentsRegistration.getComponents();
    const components = Object.keys(registeredComponents).map((key) => {
      const ng1Component = registeredComponents[key];
      const componentName = key.startsWith("salaxy") ? key.substring(6) : key.startsWith("sxy") ? "Sxy" + key.substring(3) : key;
      const controllerName = ng1Component.controller.name;
      const controller = classes.find((x) => x.name === controllerName);
      const componentClass = classes.find((x) => x.name === componentName);
      if (!componentClass) {
        console.error("Component not found:" + componentName);
        return null;
      }
      componentClass.kind = "component";
      if (controller) {
        controller.usages = controller.usages || [];
        controller.usages.push(componentName);
      }
      const result = {
        name: componentName,
        kind: "component",
        elem: this.snakeCase(key),
        controller: controllerName,
        transclude: ng1Component.transclude || null,
        require: ng1Component.require || null,
        defaultTemplate: ng1Component.defaultTemplate,
        attributes: [],
        id: componentClass.id,
        lib: componentClass.lib,
        shortText: componentClass.shortText,
        group: componentClass.group,
        descr: componentClass.descr,
        example: componentClass.example,
        exampleUrls: componentClass.exampleUrls,
        rawKind: componentClass.rawKind,
        source: componentClass.source,
        screenShots: componentClass.screenShots
      };
      if (!result.exampleUrls?.length) {
        result.exampleUrls = [`/ng1/examples/${result.group}/${result.name}.html`];
      }
      if (!result.screenShots?.length) {
        result.screenShots = [`/ng1/screen-shots/${result.group}/${result.name}.png`];
      }
      result.attributes = Object.keys(ng1Component.bindings).map((bindingKey) => {
        const prop = controller ? controller.members.find((x) => x.name === bindingKey) : null;
        const attrResult = {
          name: bindingKey,
          attr: this.snakeCase(bindingKey),
          binding: ng1Component.bindings[bindingKey],
          shortText: "ERR: Documentation not found",
          descr: "ERR: Documentation not found",
          type: {
            type: "intrinsic",
            name: "any"
          },
          controller: controller ? controller.name : null,
          example: prop ? prop.example : null
        };
        if (prop) {
          attrResult.rawKind = prop.rawKind;
          attrResult.shortText = prop.shortText;
          attrResult.descr = prop.descr;
          attrResult.type = prop.type;
          attrResult.example = prop.example;
        } else {
          let bindingTypedoc = null;
          const componentTypedoc = typedoc.children.find((x) => x.name === componentName);
          if (componentTypedoc && componentTypedoc.children.find((x) => x.name === "bindings") && componentTypedoc.children.find((x) => x.name === "bindings").children) {
            bindingTypedoc = componentTypedoc.children.find((x) => x.name === "bindings").children.find((x) => x.name === bindingKey);
            TypedocLogic.setDeclrProperties(attrResult, bindingTypedoc);
          }
          attrResult.descr += "\n\nThe attribute is only a component binding - not available in the controller.";
          if (attrResult.binding === "@") {
            attrResult.type = {
              type: "intrinsic",
              name: "string"
            };
          }
        }
        return attrResult;
      });
      if (result.transclude) {
        if (result.transclude === true) {
          result.transclude = "single";
        } else {
          Object.keys(result.transclude).forEach((key2) => {
            const val = result.transclude[key2];
            result.transclude[key2] = {
              original: val,
              isOptional: val.startsWith("?"),
              elem: this.snakeCase(val.replace("?", ""))
            };
          });
        }
      }
      if (result.require) {
        Object.keys(result.require).forEach((key2) => {
          const val = result.require[key2];
          const isOptional = val.indexOf("?") >= 0;
          const name = val.replace("?", "").replace("^", "").replace("^", "");
          const locate = val.indexOf("^^") >= 0 ? "parents" : val.indexOf("^") >= 0 ? "currentAndParents" : "current";
          const attr = this.snakeCase(name);
          let shortText;
          if (locate === "current") {
            shortText = `The component ${isOptional ? "MAY" : "MUST"} have directive ${attr} (controller ${name}).`;
            result.attributes.splice(0, 0, {
              attr,
              binding: "<",
              example: `${"```"}html
<${result.elem} ${attr}="$ctrl.current.someValue"></${result.elem}>
${"```"}`,
              controller: name,
              name,
              shortText,
              type: {type: "intrinsic", name: "any"},
              descr: shortText,
              rawKind: null
            });
          } else {
            shortText = (locate === "currentAndParents" ? "The component or one its parents" : "One of the parent elements of the component") + ` ${isOptional ? "MAY" : "MUST"} have controller "${name}" (directive or component "${attr}").`;
          }
          result.require[key2] = {
            original: val,
            name,
            isOptional,
            locate,
            elem: attr,
            shortText
          };
        });
      }
      return result;
    }).filter((x) => x != null);
    return components;
  }
  snakeCase(name) {
    const SNAKE_CASE_REGEXP = /[A-Z]/g;
    return name.replace(SNAKE_CASE_REGEXP, (letter, pos) => {
      return (pos ? "-" : "") + letter.toLowerCase();
    });
  }
}
DocsService.$inject = ["$rootScope", "$http", "$q"];
