import angular from "../../_snowpack/pkg/angular.js";
import {SitemapHelper} from "../../_snowpack/link/dist/@salaxy/ng1/salaxy-lib-ng1.js";
import {AssureBo, Example} from "./components/index.js";
import {DemoDataController, DocsComponentsController} from "./controllers/index.js";
import {DocsDirectives, DocsFilters, DocsService} from "./other/index.js";
import {sitemap} from "./sitemap.js";
import {Translations} from "../../_snowpack/link/dist/@salaxy/core/salaxy-lib-core.js";
export const module = angular.module("Salaxy.ng1", ["ngRoute", "salaxy.ng1.components.all"]).config([
  "RouteHelperProvider",
  function(routeHelperProvider) {
    routeHelperProvider.setCustomSectionRoot("/ng1").homeDefault("about").customSection("about").customSection("docs").customSection("forms").customSection("i18n").customSection("service-model").defaultSection("taxcards").customSection("omapalkka").addPersonRoutes().commit();
  }
]).constant("SITEMAP", sitemap).config(["SITEMAP", function(sitemap2) {
  const standard = SitemapHelper.getAllRolesSiteMap();
  standard[0].url = "#/home";
  sitemap2.find((x) => x.id === "company-site").children.push(...standard);
}]).run([
  "SessionService",
  function(sessionService) {
    sessionService.signInErrorUrl = "/home/error-oauth";
    Translations.setLocalesPath("/locales");
  }
]).run(["OnboardingService", function(onboardingService) {
}]).controller("DemoDataController", DemoDataController).controller("DocsComponentsController", DocsComponentsController).component("sxydocExample", new Example()).component("sxydocAssureBo", new AssureBo()).filter("sxydevCode", DocsFilters.sxydevCode).filter("sxydevMarkdown", DocsFilters.sxydevMarkdown).filter("sxydevType", DocsFilters.sxydevType).service("DocsService", DocsService).directive("sxydevInclude", DocsDirectives.sxydevInclude);
