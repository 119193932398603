export const sitemap = [
  {
    title: "About @salaxy/ng1",
    url: "#/",
    id: "about",
    params: {
      features: {}
    }
  },
  {
    title: "Components",
    url: "#/docs/components",
    id: "components",
    children: [
      {
        title: "Examples",
        isFullWidth: true,
        visibility: "hidden",
        url: "#/docs/example/*"
      },
      {
        title: "Account edit and view",
        url: "#/docs/components/account",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/account/*"
        }]
      },
      {
        title: "Calculation",
        url: "#/docs/components/calc",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/calc/*"
        }]
      },
      {
        title: "Communications",
        url: "#/docs/components/communications",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/communications/*"
        }]
      },
      {
        title: "Form controls",
        url: "#/docs/components/form-controls",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/form-controls/*"
        }]
      },
      {
        title: "Helpers and tools",
        url: "#/docs/components/helpers",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/helpers/*"
        }]
      },
      {
        title: "Payments and invoices",
        url: "#/docs/components/invoices",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/invoices/*"
        }]
      },
      {
        title: "Obsolete and phasing out",
        url: "#/docs/components/obsolete",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/obsolete/*"
        }]
      },
      {
        title: "Personal: Worker/Household webs",
        url: "#/docs/components/personal",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/personal/*"
        }]
      },
      {
        title: "OData lists and querying",
        url: "#/docs/components/odata",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/odata/*"
        }]
      },
      {
        title: "Reports and accounting",
        url: "#/docs/components/report",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/report/*"
        }]
      },
      {
        title: "Settings",
        url: "#/docs/components/settings",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/settings/*"
        }]
      },
      {
        title: "Under construction",
        url: "#/docs/components/undercon",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/undercon/*"
        }]
      },
      {
        title: "Workers and employment relation",
        url: "#/docs/components/worker",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/worker/*"
        }]
      },
      {
        title: "Workflow and timers",
        url: "#/docs/components/workflow",
        children: [{
          title: "Components",
          isFullWidth: true,
          visibility: "hidden",
          url: "#/docs/components/workflow/*"
        }]
      }
    ]
  },
  {
    title: "Forms (sxy-form)",
    url: "#/forms/index",
    id: "forms",
    children: [
      {
        title: "Overview",
        url: "#/forms/index"
      },
      {
        title: "Basic attributes",
        url: "#/forms/attributes"
      },
      {
        title: "Texts (string)",
        url: "#/forms/string"
      },
      {
        title: "Yes/no (boolean)",
        url: "#/forms/boolean"
      },
      {
        title: "Number",
        url: "#/forms/number"
      },
      {
        title: "Integer",
        url: "#/forms/integer"
      },
      {
        title: "Lists (array)",
        url: "#/forms/array"
      },
      {
        title: "Other (object)",
        url: "#/forms/object"
      },
      {
        title: "Open API 2 support",
        url: "#/forms/openapi2"
      },
      {
        title: "Errors",
        url: "#/forms/error"
      }
    ]
  },
  {
    title: "AngularJS Reference",
    url: "#/docs/controllers",
    id: "reference",
    children: [
      {
        title: "Controllers",
        url: "#/docs/controllers",
        id: "controllers",
        children: [
          {
            title: "Account edit and view",
            isFullWidth: true,
            url: "#/docs/controllers/account/*"
          },
          {
            title: "Base classes",
            isFullWidth: true,
            url: "#/docs/controllers/bases/*"
          },
          {
            title: "Calculation",
            isFullWidth: true,
            url: "#/docs/controllers/calc/*"
          },
          {
            title: "Communications",
            isFullWidth: true,
            url: "#/docs/controllers/communications/*"
          },
          {
            title: "Form controls",
            isFullWidth: true,
            url: "#/docs/controllers/form-controls/*"
          },
          {
            title: "Helpers and tools",
            isFullWidth: true,
            url: "#/docs/controllers/helpers/*"
          },
          {
            title: "Payments and invoices",
            isFullWidth: true,
            url: "#/docs/controllers/invoices/*"
          },
          {
            title: "Modal dialogs",
            isFullWidth: true,
            url: "#/docs/controllers/modals/*"
          },
          {
            title: "Obsolete and phasing out",
            isFullWidth: true,
            url: "#/docs/controllers/obsolete/*"
          },
          {
            title: "Personal: Worker/Household webs",
            isFullWidth: true,
            url: "#/docs/controllers/personal/*"
          },
          {
            title: "OData lists and querying",
            isFullWidth: true,
            url: "#/docs/controllers/odata/*"
          },
          {
            title: "Reports and accounting",
            isFullWidth: true,
            url: "#/docs/controllers/report/*"
          },
          {
            title: "Settings",
            isFullWidth: true,
            url: "#/docs/controllers/settings/*"
          },
          {
            title: "Under construction",
            isFullWidth: true,
            url: "#/docs/controllers/undercon/*"
          },
          {
            title: "Workers and employment relation",
            isFullWidth: true,
            url: "#/docs/controllers/worker/*"
          },
          {
            title: "Workflow and timers",
            isFullWidth: true,
            url: "#/docs/controllers/workflow/*"
          }
        ]
      },
      {
        title: "Services",
        url: "#/docs/services",
        id: "services",
        children: [
          {
            title: "Services overview",
            url: "#/docs/services"
          },
          {
            title: "Services reference",
            url: "#/docs/classes/services/*",
            isFullWidth: true
          }
        ]
      },
      {
        title: "Filters, directives etc.",
        url: "#/docs/other",
        id: "other",
        children: [
          {
            title: "Filters",
            url: "#/docs/filters",
            isFullWidth: true
          },
          {
            title: "Directives",
            url: "#/docs/directives",
            isFullWidth: true
          }
        ]
      },
      {
        title: "NG1 enumerations",
        url: "#/docs/enums/ng1"
      },
      {
        title: "Full NG1-library",
        url: "#/docs/classes/ng1",
        isFullWidth: true
      }
    ]
  },
  {
    title: "Core Reference",
    url: "#/docs/enums",
    id: "model",
    isFullWidth: true,
    children: [
      {
        title: "All enums",
        url: "#/docs/enums/*",
        visibility: "hidden",
        isFullWidth: true
      },
      {
        title: "All classes",
        url: "#/docs/classes/*",
        visibility: "hidden",
        isFullWidth: true
      },
      {
        title: "Data model (interfaces)",
        url: "#/docs/classes/model/*",
        isFullWidth: true
      },
      {
        title: "Enumerations",
        url: "#/docs/enums/core",
        isFullWidth: true
      },
      {
        title: "API classes (stubs)",
        url: "#/docs/classes/api/*",
        isFullWidth: true
      },
      {
        title: "Business logic",
        url: "#/docs/classes/logic/*",
        isFullWidth: true
      },
      {
        title: "Generic Utils",
        url: "#/docs/classes/util/*",
        isFullWidth: true
      },
      {
        title: "Usecases",
        url: "#/docs/classes/usecase/*",
        isFullWidth: true
      },
      {
        title: "Full core library",
        url: "#/docs/classes/core",
        isFullWidth: true
      }
    ]
  },
  {
    title: "Company demo site",
    url: "#/home",
    id: "company-site",
    children: []
  }
];
