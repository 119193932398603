export class SecurityDemoController {
  constructor(ajax, $scope) {
    this.ajax = ajax;
    this.model = {};
    this.postResult = null;
  }
  $onInit() {
  }
  postAssureCompanyAccount() {
    this.postResult = "";
    this.ajax.postJSON("/partner/company", this.model).then((result) => {
      this.postResult = result;
    }).catch((error) => {
      this.postResult = error.data || error;
    });
  }
}
SecurityDemoController.$inject = ["AjaxNg1", "$scope"];
