export class DemoDataController {
  constructor(uiHelpers, alertService, calculations) {
    this.uiHelpers = uiHelpers;
    this.alertService = alertService;
    this.calculations = calculations;
  }
  $onInit() {
  }
  get currentCalc() {
    if (window.sessionStorage && !this._currentCalc) {
      const storedItem = window.sessionStorage.getItem("currentCalc");
      if (storedItem) {
        this._currentCalc = JSON.parse(storedItem);
      }
    }
    return this._currentCalc;
  }
  set currentCalc(value) {
    this._currentCalc = value;
    if (window.sessionStorage) {
      window.sessionStorage.setItem("currentCalc", JSON.stringify(value));
    }
  }
  addAlert(alertHtml, type) {
    switch (type) {
      case "danger":
        this.alertService.addError(alertHtml);
        break;
      case "warning":
        this.alertService.addWarning(alertHtml);
        break;
      case "success":
        this.alertService.addSuccess(alertHtml);
        break;
      case "info":
        this.alertService.addInfo(alertHtml);
        break;
    }
  }
  selectCalculation(type) {
    if (type == "new") {
      this.currentCalc = this.calculations.getBlank();
    } else {
      this.uiHelpers.openSelectCalcs(type, "Select a calculation for demo").then((result) => {
        if (result.action == "ok" && result.item.length > 0) {
          const loader = this.uiHelpers.showLoading();
          this.calculations.getSingle(result.item[0].id).then((calc) => {
            this.currentCalc = calc;
            loader.dismiss();
          });
        }
      });
    }
  }
}
DemoDataController.$inject = ["UiHelpers", "AlertService", "Calculations"];
